import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footerHeading">
        VULISCORE ® Vuliscore Is SAHPRA Approved ✓
      </div>
      <div className="container">
        <div className="row">
          <div class="footer-col">
            <h4>Physical Address</h4>
            <ul>
              <li>Virtual Premises</li>
              <li>Moving To Offices Soon</li>
            </ul>
          </div>

          <div class="footer-col">
            <h4>Contact Person</h4>
            <ul>
              <li>Sash</li>
              <li>(+27) 66 146 7140</li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>Email Address</h4>
            <ul>
              <li>
                <a href="mailto:info@vuliscore.co.za">info@vuliscore.co.za</a>
              </li>
              <li>
                <a href="mailto:sales@vuliscore.co.za">sales@vuliscore.co.za</a>
              </li>
              <li>
                <a href="mailto:deliveries@vuliscore.co.za">
                  deliveries@vuliscore.co.za
                </a>
              </li>
              <li>
                <a href="mailto:orders@vuliscore.co.za">
                  orders@vuliscore.co.za
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";
import "./ContactInformation.css";
import ContactImage from "./contactusimage.jpg";

function ContactInformation() {
  return (
    <div className="contact-information-wrapper">
      <div className="contact-information">
        <div className="contact-section">
          <div className="contact-title">Physical Address</div>
          <div className="contact-info">Virtual Premises</div>
          <div className="contact-info">Moving To Offices Soon</div>
        </div>
        <div className="contact-section">
          <div className="contact-title">Contact Person</div>
          <div className="contact-info">Sash</div>
          <div className="contact-info">(+27) 66 146 7140</div>
        </div>
        <div className="contact-section">
          <div className="contact-title">Email Address</div>
          <a href="mailto:info@vuliscore.co.za" className="contact-info">
            info@vuliscore.co.za
          </a>
          <br></br>
          <a href="mailto:sales@vuliscore.co.za" className="contact-info">
            sales@vuliscore.co.za
          </a>
          <br></br>
          <a href="mailto:deliveries@vuliscore.co.za" className="contact-info">
            deliveries@vuliscore.co.za
          </a>
          <br></br>
          <a href="mailto:orders@vuliscore.co.za" className="contact-info">
            orders@vuliscore.co.za
          </a>
          <br></br>
        </div>
      </div>
    </div>
  );
}

export default ContactInformation;
